
import { getFormattedTime } from "@/services/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "DateRangePicker",

  props: {
    value: {
      required: false,
      type: Array
    },
    label: {
      required: false,
      default: "",
      type: String
    },
    min: {
      required: false,
      default: new Date("1970-01-01").toISOString(),
      type: String
    },
    max: {
      required: false,
      default: new Date("2100-01-01").toISOString(),
      type: String
    },
    hideDetails: {
      required: false,
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    getFormattedTime: getFormattedTime as Function,
    dates: [] as Array<number | string | Date>,
    menu: false as boolean
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (this.value) {
          this.dates = this.value as Array<number>;
        } else {
          this.dates = [];
        }
      }
    }
  },

  computed: {
    formattedDate(): string {
      const [from, to]: Array<number | string | Date> = [...this.dates].sort(
        //@ts-ignore
        (a: string, b: string): number => new Date(a) - new Date(b)
      );

      if (from && to) {
        const dateFrom = new Date(from);
        const dateTo = new Date(to);
        const [
          formattedDateFrom
        ]: Array<string> = dateFrom.toLocaleString().split(",");

        const [formattedDateTo]: Array<string> = dateTo
          .toLocaleString()
          .split(",");

        if (formattedDateFrom === formattedDateTo) {
          return formattedDateFrom;
        }

        return `${formattedDateFrom} - ${formattedDateTo}`;
      }
      return "";
    }
  },

  methods: {
    changeData(): void {
      if (this.dates.length === 1) {
        const [date] = this.dates;
        this.$emit("input", [date, date]);
      } else {
        this.$emit(
          "input",
          this.dates.sort(
            //@ts-ignore
            (a: string, b: string): number => new Date(a) - new Date(b)
          )
        );
      }
      this.menu = false;
    },
    close(): void {
      this.dates = [];
      this.changeData();
      this.menu = false;
    },
    toggle() {
      this.menu = !this.menu;
    },
    setLastDays(days: number): void {
      const dateTo = new Date();
      const timeDifference = dateTo.getTime() / 1000 - days * 86400;
      const dateFrom = new Date(timeDifference * 1000);

      this.dates = [dateFrom.toISOString(), dateTo.toISOString()];
    },
    setToday() {
      this.dates = [new Date().toISOString(), new Date().toISOString()];
    },
    setThisWeek() {
      const currentDay = new Date().getDay();

      this.setLastDays(currentDay);
    },
    setThisMonth() {
      const currentDay = new Date().getDate();

      this.setLastDays(currentDay);
    },
    setThisYear() {
      const currentYear = new Date().getFullYear();

      this.dates = [
        new Date(`${currentYear}-01-01`).toISOString(),
        new Date().toISOString()
      ];
    }
  }
});
