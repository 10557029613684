var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-6"},[_c('v-card',{staticClass:"elevation-4"},[_c('v-card-text',[_c('v-row',{staticClass:"mb-2"},[_c('v-col',{attrs:{"cols":"9","align-self":"center"}},[_c('v-btn',{staticClass:"pl-2 pr-3",attrs:{"depressed":"","outlined":"","color":"primary"},on:{"click":function($event){_vm.createModal = true}}},[_c('v-icon',[_vm._v("mdi-view-grid-plus")]),_c('span',{staticClass:"ml-2 body-2 text-normalize"},[_vm._v(" Добавить пользователя ")])],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"3","align-self":"center"}},[_c('v-btn',{staticClass:"mr-2 mt-1",attrs:{"icon":"","title":"Обновить таблицу"},on:{"click":function($event){return _vm.loadData()}}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)],1),_c('v-card',{staticClass:"table-container",attrs:{"outlined":""}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"footer-props":{
            'items-per-page-options': _vm.itemsPerPageOptions
          },"items-per-page":_vm.pagination.size,"page":_vm.pagination.number,"server-items-length":_vm.totalItems,"sort-by":_vm.sort.sortBy,"sort-desc":_vm.sort.sortDesc},on:{"update:itemsPerPage":function($event){return _vm.$set(_vm.pagination, "size", $event)},"update:items-per-page":[function($event){return _vm.$set(_vm.pagination, "size", $event)},function($event){return _vm.debouncedLoadData(100)}],"update:page":[function($event){return _vm.$set(_vm.pagination, "number", $event)},function($event){return _vm.changePage()}],"update:sortBy":function($event){return _vm.$set(_vm.sort, "sortBy", $event)},"update:sort-by":[function($event){return _vm.$set(_vm.sort, "sortBy", $event)},function($event){return _vm.debouncedLoadData(100)}],"update:sortDesc":function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},"update:sort-desc":[function($event){return _vm.$set(_vm.sort, "sortDesc", $event)},function($event){return _vm.debouncedLoadData(100)}]},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header,headerIndex){return {key:("item." + (header.value)),fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{key:headerIndex,attrs:{"max-width":"500","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"d-inline-block text-truncate",staticStyle:{"max-width":"200px"}},'span',attrs,false),on),[_vm._v(_vm._s(item[header.value])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item[header.value]))])])]}}}),{key:"item.is_active",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.is_active)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])],1)]}},{key:"item.icon",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('image-viewer',{attrs:{"rounded":"","src":item.icon}})],1)]}},{key:"item.showcard",fn:function(ref){
          var item = ref.item;
return [(item.showcard)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" mdi-close ")])]}},{key:"item._actions",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center align-center"},[_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{attrs:{"title":_vm.$t('global_buttons.edit'),"x-small":"","fab":"","text":""},on:{"click":function($event){return _vm.openEditModal(item.id)}}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" mdi-pencil ")])],1)],1),_c('div',{staticClass:"d-flex justify-center"},[_c('v-btn',{directives:[{name:"confirm",rawName:"v-confirm",value:({
                    title: _vm.$t('global_alert.deletion_title'),
                    message: _vm.$t('global_alert.deletion_text'),
                    callback: function () {
                      _vm.deleteRow(item.id);
                    }
                  }),expression:"{\n                    title: $t('global_alert.deletion_title'),\n                    message: $t('global_alert.deletion_text'),\n                    callback: () => {\n                      deleteRow(item.id);\n                    }\n                  }"}],attrs:{"title":_vm.$t('global_buttons.delete'),"x-small":"","fab":"","color":"error","text":""}},[_c('v-icon',{attrs:{"size":"16"}},[_vm._v(" "+_vm._s(item.is_active ? "mdi-close-thick" : "mdi-delete")+" ")])],1)],1)])]}}],null,true)})],1)],1)],1),(_vm.createModal)?_c('create',{on:{"close":this.loadData},model:{value:(_vm.createModal),callback:function ($$v) {_vm.createModal=$$v},expression:"createModal"}}):_vm._e(),(_vm.editModal)?_c('edit',{attrs:{"item":_vm.selectedItem},on:{"close":this.loadData},model:{value:(_vm.editModal),callback:function ($$v) {_vm.editModal=$$v},expression:"editModal"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }