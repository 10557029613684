
import Vue from "vue";

export default Vue.extend({
  name: "HeadersToggle",

  props: {
    headers: {
      required: true,
      type: Object
    }
  },

  data: () => ({
    panels: {} as any,
    panel: [0, 1],
    disabled: false,
    readonly: false
  }),

  mounted() {
    this.getExpansionPanels();
  },

  methods: {
    someHeadersVisible(childrenArray: Array<any>): boolean {
      if (!childrenArray) return false;

      let state = false;

      if (childrenArray.some((item: any) => item.visible === true)) {
        state = true;
      }

      if (childrenArray.every((item: any) => item.visible === true)) {
        state = false;
      }

      if (childrenArray.every((item: any) => item.visible === false)) {
        state = false;
      }

      return state;
    },
    changeCheckboxState(obj: any): void {
      obj.visible = !obj.visible;
      if (!obj.children) return;

      obj.children.map((item: any) => (item.visible = obj.visible));
    },
    getExpansionPanels() {
      for (const item in this.headers) {
        if (this.headers[item].children) {
          this.panels[item] = true;
        }
      }
    },
    openExpansionPanel(value: string) {
      this.panels[value] = !this.panels[value];
    }
  }
});
