import debounce from "lodash/fp/debounce";
import Vue from "vue";

export default Vue.extend({
  data: () => ({
    debounce,
    itemsPerPageOptions: [4, 8, 12, 20, 48, 100] as Array<number>,
    totalItems: 0 as number,
    pagination: {
      number: 1 as number,
      size: 20 as number
    },
    sort: {
      sortDesc: [] as Array<string>,
      sortBy: [] as Array<string>
    },
    debounced: null as any
  }),

  beforeMount() {
    this.pagination.number = Number(this.$route.params.page);
  },

  methods: {
    debouncedLoadData(timeout: number): void {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(timeout, this.loadData);
      this.debounced();
    },
    getSortParams(): string | undefined {
      if (this.sort.sortBy.length) {
        const [sortBy]: Array<string> = this.sort.sortBy;
        const [sortDesc]: Array<string> = this.sort.sortDesc;
        return `${sortDesc ? "" : "-"}${sortBy}`;
      }
      return undefined;
    },
    async changePage(): Promise<void> {
      const [, query] = this.$route.fullPath.split("?");
      try {
        await this.$router.push(
          `/${this.$route.meta?.module}/${query ? `?${query}&` : ""}`
        );
        await this.debouncedLoadData(100);
      } catch {
        await Promise.resolve();
      }
    }
  }
});
