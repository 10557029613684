
import Vue from "vue";
import store from "@/store";
import DatePicker from "@/components/form-components/DatePicker.vue";
import PhonePicker from "@/components/form-components/PhonePicker.vue";

export default Vue.extend({
  name: "CardsShow",

  components: {
    PhonePicker,
    DatePicker
  },

  data: () => ({
    currentCard: {} as any,
    items: [] as Array<any>,
    model: {},
    selects: {},
    types: {
      1: {
        icon: "mdi-comment",
        text: "Текстовое-поле"
      },
      2: {
        icon: "mdi-calendar",
        text: "Дата"
      },
      3: {
        icon: "mdi-menu",
        text: "Список"
      },
      4: {
        icon: "mdi-comment-text",
        text: "Комментарий"
      },
      5: {
        icon: "mdi-phone",
        text: "Номер телефона"
      }
    },
    timer: {
      hours: 0 as number,
      minutes: 0 as number,
      seconds: 0 as number
    } as any,
    timeInterval: null as any
  }),

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData() {
      try {
        this.currentCard = await this.$API
          .cards()
          .getClientCardById(Number(this.$route.query.id));

        this.items = this.currentCard.data;
        this.items.find((item: any) => Number(item.type) === 5).value =
          "88499939999";
      } catch (e) {
        await this.$store.dispatch(
          "alert/showError",
          "Возникла неожиданная ошибка"
        );
      }
    },
    async submit() {
      try {
        await this.$router.push("/cards");
        await this.$store.dispatch("alert/showInfo", "Ещё не сделали");
      } catch (e) {
        await this.$store.dispatch("alert/showInfo", "Ещё не сделали");
      }
    },
    async cancel() {
      await this.$router.push("/cards");
    }
  }
});
