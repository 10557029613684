
import Vue from "vue";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";

import headers from "../config/cards-create-headers.json";

export default Vue.extend({
  name: "CardsEdit",

  mixins: [tablePagination, tableFilters],

  data: () => ({
    headers,
    name: "" as string,
    items: [] as Array<any>,
    selects: {
      fields: [] as Array<any>
    } as any,
    number: 8888888888 as number,
    deleteAllRows: false as boolean,
    rerenderKey: 0 as number,
    index: 0 as number,
    visibleListFields: [] as Array<boolean | null>
  }),

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API
          .cards()
          .getClientCardById(Number(this.$route.query.id));
        this.selects.fields = await this.$API.cards().getFieldsList();

        this.name = response.name;
        this.items = response.data.map((item: any) => ({
          ...item,
          type: Number(item.type)
        }));
      } catch (e) {
        console.log(e);
        await this.$store.dispatch("alert/showError", "Что-то пошло не так");
      }
      this.loading = false;
    },
    async submit(): Promise<any> {
      try {
        if (this.validate()) {
          this.$forceUpdate();
          const objectToSend = {
            id: Number(this.$route.query.id),
            name: this.name,
            data: [
              ...this.items.map((item: any, index: number) => ({
                ...item,
                id: index,
                checkbox: false,
                list: item.list || [],
                type: item.type.toString()
              }))
            ]
          };

          await this.$API.cards().create(objectToSend);
          await this.$router.push("/cards");
          await this.$store.dispatch(
            "alert/showSuccess",
            "Карточка создана успешно!"
          );
        } else {
          await this.$store.dispatch("alert/showError", "Ошибка валидации!");
        }
      } catch (e) {
        console.log(e);
        await this.$store.dispatch("alert/showError", "Что-то пошло не так");
      }
    },
    addRow(): void {
      this.items.push({});
      this.changeType();
    },
    changeType() {
      const arr: Array<boolean | null> = [];

      this.items.forEach((item: any) => {
        if (item.type !== 3) {
          item.list = [];
        }
        if (item.type === 3) {
          if (!item.list.length) {
            item.list.push("");
          }
          item.hasOwnProperty("list") > 0 ? arr.push(false) : arr.push(true);
        } else {
          arr.push(null);
        }
      });
      this.visibleListFields = arr;
      this.$forceUpdate();
    },
    getIcon(id: number): string {
      if (id) {
        return this.selects.fields[id - 1].icon;
      }
      return "mdi-help";
    },
    deleteRow(index: number): void {
      const arr = this.items;
      arr.splice(index, 1);
      this.items = arr;
      this.resetRowsId();
    },
    resetRowsId(): void {
      const arr = this.items;
      arr.map(
        (item, index) =>
          (item = {
            id: index
          })
      );
      this.items = arr;
    },
    validate(): boolean {
      if (
        !this.name ||
        !this.items.length ||
        !this.items.some((item: any) => item.type === 5) ||
        !this.items.every(
          (item: any) =>
            item.hasOwnProperty("title") &&
            item.title &&
            item.type &&
            item.list.every((item: any) => item)
        )
      ) {
        return false;
      }

      return true;
    },
    addListItem(obj: any) {
      obj.list.push("");
      this.forceUpd();
    },
    removeListItem(item: any, index: number) {
      item.list.splice(index, 1);
      this.forceUpd();
    },
    toggleListFields(id: number) {
      this.visibleListFields[id] = !this.visibleListFields[id];
      this.$forceUpdate();
    },
    forceUpd() {
      this.rerenderKey += 1;
    },
    removeSelected() {
      let arr = this.items;

      arr = arr.filter((item: any) => item.checkbox);
      this.$forceUpdate();
    },
    toggleDeleteCheckboxes() {
      this.deleteAllRows = !this.deleteAllRows;
      for (const item of this.items) {
        if (!item.hasOwnProperty("checkbox")) {
          item.checkbox = false;
        }
        item.checkbox = this.deleteAllRows;
      }
      this.forceUpd();
    },
    parsedFields(arr: Array<any>): Array<any> {
      const array = [];

      for (const item of arr) {
        array.push({
          text: item,
          value: item
        });
      }
      return array;
    }
  }
});
