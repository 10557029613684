
import Vue from "vue";

export default Vue.extend({
  name: "Metrics",

  props: {
    alias: {
      type: String
    }
  },

  data: () => ({
    metrics: {} as any,
    expansionPanels: {
      phoneCalls: true,
      operators: true,
      avg: true,
      avgTotal: true,
      average: true
    } as any,
    limits: {
      phoneCalls: {},
      average: {},
      avg: {},
      avgTotal: {
        sessionIdleDuration: "00:00:00",
        sessionDuration: "00:00:00"
      },
      operators: {}
    } as any,
    menu1: false,
    menu2: false
  }),

  mounted() {
    this.metrics = require(`../config/metrics-${this.alias}-model.json`);
    this.setFromLocalStorage();
  },

  watch: {
    metrics: {
      deep: true,
      handler() {
        localStorage.setItem(
          `${this.alias}Metrics`,
          JSON.stringify(this.metrics)
        );
      }
    },
    limits: {
      deep: true,
      handler() {
        localStorage.setItem(
          `${this.alias}Limits`,
          JSON.stringify(this.limits)
        );
        this.$emit("input", {
          limits: this.limits,
          metrics: this.metrics
        });
      }
    }
  },

  methods: {
    toggleMetric(metricParent: any, metricChild: any, operator: any) {
      const child = this.metrics[metricParent].children[metricChild];

      if (operator === "less" && child.greater) {
        child.greater = false;
      } else if (operator === "greater" && child.less) {
        child.less = false;
      }

      child.valueActive = child.less || child.equal || child.greater;

      if (
        (child.time && !this.limits[metricParent][child.value]) ||
        (child.time && !child.greater && !child.less && !child.equal)
      ) {
        this.clearTime(metricParent, child.value);
      } else if (!child.time && !child.greater && !child.less && !child.equal) {
        this.limits[metricParent][child.value] = null;
      }
    },
    setFromLocalStorage() {
      if (
        localStorage.getItem(`${this.alias}Metrics`) === null ||
        localStorage.getItem(`${this.alias}Limits`) === null
      ) {
        localStorage.setItem(
          `${this.alias}Metrics`,
          JSON.stringify(this.metrics)
        );
        localStorage.setItem(
          `${this.alias}Limits`,
          JSON.stringify(this.limits)
        );
      } else {
        this.metrics = JSON.parse(
          // @ts-ignore
          localStorage.getItem(`${this.alias}Metrics`)
        );
        this.limits = JSON.parse(
          // @ts-ignore
          localStorage.getItem(`${this.alias}Limits`)
        );
      }
    },
    clearTime(parent: string, child: string) {
      this.limits[parent][child] = "00:00:00";
    }
  }
});
