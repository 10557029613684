
import Vue from "vue";
import API from "@/api/API";

import indicatorHeaders from "../config/headers.json";
import operatorHeaders from "../config/operators-headers.json";
import statisticHeaders from "../config/statistic-headers.json";

import Metrics from "@/modules/apm/components/Metrics.vue";
import HeadersToggle from "@/modules/apm/components/HeadersToggle.vue";

export default Vue.extend({
  name: "Users",

  components: {
    Metrics,
    HeadersToggle
  },

  data: () => ({
    loading: false as boolean,
    selectedGroup: "" as string,
    search: {
      statistics: "",
      operators: ""
    } as any,
    filter: {} as any,

    indicators: {
      items: [] as Array<any>,
      headers: indicatorHeaders,
      model: {
        phoneCalls: {
          text: "Количество звонков",
          children: [
            {
              text: "Поступило",
              value: "total"
            },
            {
              text: "Потеряно",
              value: "missed"
            }
          ]
        },
        avgDuration: {
          text: "Средняя длительность",
          children: [
            {
              text: "Разговоров",
              value: "averageDurationTalk"
            },
            {
              text: "Ожидания принятых",
              value: "averageDurationAnswer"
            },
            {
              text: "Ожидания потерянных",
              value: "averageDurationMissed"
            }
          ]
        },
        amountOperators: {
          text: "Количество операторов",
          children: [
            {
              text: "Всего",
              value: "totalOperators",
              icon: true
            },
            {
              text: "Ожидают звонка",
              value: "idle",
              icon: true
            },
            {
              text: "На перерыве",
              value: "paused",
              icon: true
            },
            {
              text: "Разговаривают",
              value: "busy",
              icon: true
            },
            {
              text: "Недоступны",
              value: "unavailable",
              icon: true
            }
          ]
        }
      } as any,
      values: {} as any
    } as any,
    statistics: {
      items: [] as Array<any>,
      headers: statisticHeaders as any,
      itemsModel: [] as Array<any>,
      visibleHeaders: [] as Array<any>
    } as any,
    operators: {
      items: [] as Array<any>,
      headers: operatorHeaders as any,
      itemsModel: [] as Array<any>,
      visibleHeaders: [] as Array<any>
    } as any,

    toggle_exclusive: null as number | null,
    expansionPanels: {
      statistic: {
        phoneCalls: true,
        operators: true,
        average: true
      },
      operators: {
        phoneCalls: true,
        avgTotal: true
      }
    } as any,
    limits: {
      operators: {},
      statistics: {}
    } as any,
    metrics: {
      statistics: {},
      operators: {}
    } as any,
    selects: {
      statuses: [
        {
          text: "Недоступны",
          icon: "mdi-phone-remove",
          value: "unavailable",
          color: "#4a4a4a"
        },
        {
          text: "Ожидают звонка",
          icon: "mdi-phone",
          value: "idle",
          color: "green"
        },
        {
          text: "Разговаривают",
          icon: "mdi-phone-in-talk",
          value: "busy",
          color: "red"
        },
        {
          text: "На перерыве",
          icon: "mdi-clock-time-seven-outline",
          value: "paused",
          color: "orange"
        }
      ]
    } as any,
    statuses: {
      totalOperators: {
        title: "",
        icon: "mdi-account-group-outline",
        color: "primary"
      },
      idle: {
        title: "Ожидают звонка",
        icon: "mdi-phone",
        color: "green"
      },
      unavailable: {
        title: "Недоступны",
        icon: "mdi-phone-remove",
        color: "disabled"
      },
      busy: {
        title: "Разговаривают",
        icon: "mdi-phone-in-talk",
        color: "red"
      },
      paused: {
        title: "На перерыве",
        icon: "mdi-clock-time-seven-outline",
        color: "orange"
      }
    } as any,
    localization: {
      statuses: {
        unavailable: "Недоступен",
        idle: "Ожидает звонка",
        paused: "На перерыве",
        busy: "Разговаривает"
      }
    } as any,

    messages: [] as Array<any>
  }),

  watch: {
    "statistics.headers": {
      deep: true,
      handler() {
        localStorage.setItem(
          "statisticsHeaders",
          JSON.stringify(this.statistics.headers)
        );

        this.statistics.itemsModel = this.getItemsModel(
          this.statistics.headers
        );
      }
    },
    "operators.headers": {
      deep: true,
      handler() {
        localStorage.setItem(
          "operatorsHeaders",
          JSON.stringify(this.operators.headers)
        );

        this.operators.itemsModel = this.getItemsModel(this.operators.headers);
      }
    }
  },

  computed: {
    filteredStatistics() {
      return this.statistics.items.filter(
        (item: any) =>
          item.description
            .toLowerCase()
            .includes(this.search.statistics.toLowerCase()) ||
          item.name.toLowerCase().includes(this.search.statistics.toLowerCase())
      );
    },
    filteredOperators() {
      return this.operators.items
        .filter(
          (item: any) =>
            item.statistics.status ===
            (this.filter.status ? this.filter.status : item.statistics.status)
        )
        .filter(
          (item: any) =>
            item.callerid
              .toLowerCase()
              .includes(this.search.operators.toLowerCase()) ||
            item.membername
              .toLowerCase()
              .includes(this.search.operators.toLowerCase())
        );
      // .map((item: any) => ({
      //   ...item,
      //   statistics: {
      //     ...item.statistics,
      //     // @ts-ignore
      //     status: this.localization.statuses[item.statistics.status]
      //   }
      // }));
    }
  },

  async mounted() {
    await this.getSearchesFromLocalStorage();
    await this.loadData();
    this.getOperatorsFromLocalStorage();
    this.getStatisticsFromLocalStorage();
    this.interval = setInterval(async () => {
      await this.loadData();
    }, 1000);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const [indicators, groups, operators] = await Promise.all([
          await API.apm().getStatistics(),
          await API.apm().getGroups(),
          this.selectedGroup
            ? await API.apm().getOperatorsByGroup(this.selectedGroup)
            : await API.apm().getOperators()
        ]);

        this.indicators.values = { ...indicators.statistic };

        this.statistics.items = [
          ...groups.groups.map((item: any) => ({
            ...item,
            statistics: {
              ...item.statistics,
              waitingCalls: this.getWaitingCalls(item.statistics)
            }
          }))
        ];

        this.operators.items = this.selectedGroup
          ? [...operators.operators_by_group]
          : [...operators.operators];
      } catch (e) {
        //
      }
      this.loading = false;
    },
    getWaitingCalls(obj: CallsInterface) {
      const result =
        Number(obj.calls) - (Number(obj.missed) + Number(obj.answer));
      return result > 0 ? result : 0;
    },
    someHeadersVisible(childrenArray: Array<any>): boolean {
      if (!childrenArray) return false;

      let state = false;

      if (childrenArray.some((item: any) => item.visible === true)) {
        state = true;
      }

      if (childrenArray.every((item: any) => item.visible === true)) {
        state = false;
      }

      if (childrenArray.every((item: any) => item.visible === false)) {
        state = false;
      }

      return state;
    },
    changeCheckboxState(obj: any): void {
      obj.visible = !obj.visible;
      if (!obj.children) return;

      obj.children.map((item: any) => (item.visible = obj.visible));
    },
    childrenVisibleLength(obj: any): void {
      if (!obj.children) return;

      const childArrayLength = obj.children.filter((item: any) => item.visible)
        .length;

      obj.visible = !!childArrayLength;
      return childArrayLength;
    },
    formattedSeconds(duration: number) {
      const hours = Math.trunc(duration / 60 / 60);
      const minutes = Math.trunc((duration / 60) % 60);
      const seconds = Math.trunc(duration % 60);

      return `${this.formatTime(hours)}:${this.formatTime(
        minutes
      )}:${this.formatTime(seconds)}`;
    },
    formatTime(time: number): string {
      if (time < 10) return `0${time}`;
      return time.toString();
    },
    selectRow(val: string) {
      if (this.selectedGroup === val) this.selectedGroup = "";
      else this.selectedGroup = val;
    },
    setFilterStatus(val: string) {
      if (this.filter.status === val) this.filter.status = "";
      else this.filter.status = val;
    },
    getOperatorsFromLocalStorage() {
      if (localStorage.getItem("operatorsHeaders") === null) {
        localStorage.setItem(
          "operatorsHeaders",
          JSON.stringify(this.operators.headers)
        );
      } else {
        this.operators.headers = JSON.parse(
          // @ts-ignore
          localStorage.getItem("operatorsHeaders")
        );
      }
      this.operators.itemsModel = this.getItemsModel(this.operators.headers);
    },
    getStatisticsFromLocalStorage() {
      if (localStorage.getItem("statisticsHeaders") === null) {
        localStorage.setItem(
          "statisticsHeaders",
          JSON.stringify(this.statistics.headers)
        );

        this.statistics.itemsModel = this.getItemsModel(
          this.statistics.headers
        );
      } else {
        this.statistics.headers = JSON.parse(
          // @ts-ignore
          localStorage.getItem("statisticsHeaders")
        );
      }
      this.statistics.itemsModel = this.getItemsModel(this.statistics.headers);
    },
    getSearchesFromLocalStorage() {
      let statisticsQuery = localStorage.getItem("searchStatistics");
      let operatorsQuery = localStorage.getItem("searchOperators");
      const statisticsMetricsQuery = localStorage.getItem("statisticsMetrics");
      const statisticsLimitsQuery = localStorage.getItem("statisticsLimits");
      const operatorsMetricsQuery = localStorage.getItem("operatorsMetrics");
      const operatorsLimitsQuery = localStorage.getItem("operatorsLimits");

      if (statisticsQuery === null) statisticsQuery = "";
      if (operatorsQuery === null) operatorsQuery = "";
      if (statisticsMetricsQuery === null) return;
      if (statisticsLimitsQuery === null) return;
      if (operatorsMetricsQuery === null) return;
      if (operatorsLimitsQuery === null) return;

      this.search.statistics = statisticsQuery;
      this.search.operators = operatorsQuery;
      this.metrics.statistics = JSON.parse(statisticsMetricsQuery);
      this.metrics.operators = JSON.parse(operatorsMetricsQuery);
      this.limits.statistics = JSON.parse(statisticsLimitsQuery);
      this.limits.operators = JSON.parse(operatorsLimitsQuery);
    },
    getItemsModel(obj: any): Array<any> {
      const arr = [];

      for (const item in obj) {
        if (obj[item].visible) {
          if (!(obj[item] as any).children) {
            arr.push({
              value: obj[item].value,
              align: obj[item].align,
              width: obj[item].width,
              statistics: obj[item].statistics
            });
          } else {
            for (const child of obj[item].children) {
              if (child.visible) {
                arr.push({
                  parentValue: obj[item].value,
                  value: child.value,
                  align: child.align,
                  width: child.width,
                  statistics: obj[item].statistics,
                  time: child.time
                });
              }
            }
          }
        }
      }

      return arr;
    },
    getCompared(comparingValue: string, comparedValue: string, obj: any) {
      if (!obj.valueActive) return;

      let operator = "";

      if (obj.less) {
        operator += "<";
      } else if (obj.greater) {
        operator += ">";
      }
      if (operator && obj.equal) {
        operator += "=";
      } else if (obj.equal) {
        operator += "==";
      }

      if (operator && comparedValue) {
        return eval(`"${comparingValue}" ${operator} "${comparedValue}"`);
      }
      return false;
    },
    updateStatisticsData(value: any) {
      this.limits.statistics = value.limits;
      this.metrics.statistics = value.metrics;
    },
    updateOperatorsData(value: any) {
      this.limits.operators = value.limits;
      this.metrics.operators = value.metrics;
    },
    handleDecimals(val: number | string): number | string {
      if (Number(val) % 1 != 0) {
        return Number(val).toFixed(2);
      }
      return val;
    }
  }
});
