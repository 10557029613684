
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import ImageViewer from "@/components/viewers/Image.vue";
import headers from "../config/headers.json";
import { Dictionary } from "vue-router/types/router";
import { parseArrayParams } from "@/services/helpers";

import Create from "@/modules/users/components/Create.vue";
import Edit from "@/modules/users/components/Edit.vue";

export default Vue.extend({
  name: "Users",

  mixins: [tablePagination, tableFilters],

  components: {
    ImageViewer,
    Create,
    Edit
  },

  data: () => ({
    parseArrayParams,
    headers,
    filter: {} as Dictionary<string | (string | null)[]>,
    items: [] as Array<any>,
    loading: false as boolean,
    selectedItem: {} as any,
    createModal: false as boolean,
    editModal: false as boolean
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const params = {
        filter: to.query,
        page: {
          number: to.params.page,
          size: 48
        }
      };
      const response = await API.users().getData(params);

      next(vm => {
        vm.setFilters(params.filter);
        vm.setServerResponse(response);
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.users().getData({
          filter: this.parseArrayParams(this.filter),
          page: this.pagination,
          sort: this.getSortParams()
        });

        this.setServerResponse(response);
      } catch (e) {
        console.log(e);
      }
      this.loading = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;

      try {
        await this.$API.users().delete(id);
        await this.$store.dispatch(
          "alert/showSuccess",
          this.$t("global_alert.successful_removal")
        );
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch("alert/showError", e);
      }

      this.loading = false;
    },
    setServerResponse({ data, recordsTotal }: any) {
      this.items = data;
      this.totalItems = recordsTotal;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    search(): void {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    openEditModal(id: number): void {
      this.selectedItem = this.items.find(item => item.id === id);
      this.editModal = true;
    }
  }
});
