
import Vue from "vue";
import { LocaleMessages } from "vue-i18n";
export default Vue.extend({
  name: "create",

  props: {
    value: {
      required: true,
      type: Boolean
    }
  },

  data: () => ({
    dialog: false as boolean,
    loaded: false as boolean,
    model: {
      showcard: false
    } as any,
    selects: {
      roles: []
    },
    errorMessages: {} as { [value: string]: Array<string> }
  }),

  computed: {
    rules() {
      return {
        required: [
          (v: string): boolean | string | LocaleMessages =>
            !!v || this.$t("global_validation.required")
        ]
      };
    }
  },

  watch: {
    value: {
      immediate: true,
      async handler() {
        this.dialog = this.value;
      }
    }
  },

  beforeMount() {
    this.loadData();
  },

  methods: {
    async loadData() {
      try {
        this.selects.roles = await this.$API.users().getRoles();
      } catch (e) {
        console.log(e);
      }
    },
    closeDialog() {
      this.dialog = false;
      this.$emit("input", this.dialog);
      this.$emit("close");
    },
    async submit(): Promise<void> {
      this.loaded = true;
      try {
        if ((this.$refs.form as Vue).validate()) {
          const model = { ...this.model };

          await this.$API.users().create(model);
          this.closeDialog();
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_create")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;

          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        console.log(e);
      }
      this.loaded = false;
    }
  }
});
