
import Vue from "vue";
import API from "@/api/API";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { Dictionary } from "vue-router/types/router";
import { parseArrayParams } from "@/services/helpers";
import headers from "../config/cards-headers.json";

export default Vue.extend({
  name: "Cards",

  mixins: [tablePagination, tableFilters],

  data: () => ({
    headers,
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    items: [] as Array<any>,
    loading: false as boolean,
    selectedItem: {} as any,
    createModal: false as boolean,
    editModal: false as boolean,
    showSearch: false as boolean
  }),

  computed: {
    user() {
      return this.$store.getters["user/info"];
    }
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const cards = await API.users().getClientsBase();

      next(vm => {
        vm.items = cards.items;
      });
    } catch (e) {
      console.log(e);
    }
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const cards = await API.users().getClientsBase();

        this.items = cards.items;
      } catch (e) {
        await this.$store.dispatch(
          "alert/showError",
          "Пройзошла неожиданная ошибка!"
        );
      }
      this.loading = false;
    },
    async deleteRow(id: number): Promise<void> {
      this.loading = true;

      try {
        await this.$API.users().delete(id);
        await this.$store.dispatch("alert/showSuccess", "Успешно удалено.");
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch(
          "alert/showError",
          "Пройзошла неожиданная ошибка!"
        );
      }

      this.loading = false;
    },
    setServerResponse({ data, recordsTotal }: any) {
      this.items = data;
      this.totalItems = recordsTotal;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    search(): void {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    async deleteSelectedRows(): Promise<any> {
      const arr = [];

      for (const item of this.items) {
        if (item.checkbox && item.id != 1) {
          arr.push(item.id);
        }
      }

      try {
        await this.$API.cards().delete({ ids: arr });
        await this.loadData();
      } catch (e) {
        await this.$store.dispatch(
          "alert/showError",
          "Пройзошла неожиданная ошибка!"
        );
      }
    },
    toggleSearch() {
      this.showSearch = !this.showSearch;

      if (this.showSearch) {
        this.$nextTick(() => {
          (this.$refs.search as Vue).focus();
        });
      }
    }
  }
});
