
import Vue from "vue";

export default Vue.extend({
  name: "FileUploader",

  props: {
    value: {
      required: false,
      default: null
    },
    label: {
      required: false,
      type: String,
      default: ""
    },
    rules: {
      required: false,
      type: Array,
      default: () => []
    },
    accept: {
      required: false,
      type: String,
      default: ""
    },
    truncateLength: {
      required: false,
      type: String,
      default: ""
    },
    hideDetails: {
      required: false,
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    model: null as File | null,
    imagePreview: "" as string | ArrayBuffer | null
  }),

  watch: {
    value: {
      immediate: true,
      handler() {
        if (typeof this.value === "string") {
          this.model = new File([], this.value || "");
        } else {
          this.model = this.value;
        }
      }
    }
  },

  methods: {
    toBase64(file: File): Promise<string | ArrayBuffer | null> {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
      });
    },
    async changeData(): Promise<void> {
      this.$emit("input", this.model);
    },
    focusInput(): void {
      document.getElementById("file-input")!.click();
    }
  }
});
