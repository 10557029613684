
import Vue from "vue";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { Dictionary } from "vue-router/types/router";
import { parseArrayParams } from "@/services/helpers";
import headers from "../config/headers-index.json";

export default Vue.extend({
  name: "ClientsIndex",

  mixins: [tablePagination, tableFilters],

  data: () => ({
    headers,
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    items: [] as Array<any>,
    loading: false as boolean,
    selectedItem: {} as any,
    createModal: false as boolean,
    editModal: false as boolean
  }),

  async mounted() {
    await this.loadData();
  },

  methods: {
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        const response = await this.$API.users().getClientsBase();

        this.items = response.items;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e);
      }
      this.loading = false;
    },
    setServerResponse({ data, recordsTotal }: any) {
      this.items = data;
      this.totalItems = recordsTotal;
    },
    setFilters(filter: Dictionary<string | (string | null)[]>): void {
      const newFilters = this.getAvailableFilters(filter);

      if (Object.keys(newFilters).length) {
        this.showFilters = true;
      }

      this.filter = newFilters;
      this.changeActiveFilters();
    },
    search(): void {
      if (this.debounced) {
        this.debounced.cancel();
      }
      this.debounced = this.debounce(500, this.applyFilters);
      this.debounced();
    },
    openEditModal(id: number): void {
      this.selectedItem = this.items.find(item => item.id === id);
      this.editModal = true;
    }
  }
});
