
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import tablePagination from "@/mixins/table-pagination";
import tableFilters from "@/mixins/table-filters";
import { Dictionary } from "vue-router/types/router";
import { parseArrayParams } from "@/services/helpers";
// import headers from "../config/headers.json";

import FileUploader from "@/components/form-components/FileUploader.vue";
import DateRangePicker from "@/components/form-components/DateRangePicker.vue";

export default Vue.extend({
  name: "ClientsShow",

  mixins: [tablePagination, tableFilters],

  components: {
    DateRangePicker,
    FileUploader
  },

  data: () => ({
    parseArrayParams,
    filter: {} as Dictionary<string | (string | null)[]>,
    headers: [] as Array<any>,
    items: [] as Array<any>,
    parsedItems: [] as Array<any>,
    itemsToDelete: [] as Array<number>,
    page: 1 as number,
    name: "" as string,
    file: null as any,
    checked: false as boolean,
    loading: false as boolean,
    deleteCurrentPage: false as boolean,
    modal: false as boolean,
    forceKey: 0 as number,
    totalItems: 0 as number
  }),

  async beforeRouteEnter(to, from, next) {
    try {
      const clients = await API.clients().getClients(
        { card_id: Number(to.params.id) },
        {}
      );

      const card = await API.cards().getClientCardById(Number(to.params.id));
      console.log(card);
      next(vm => {
        vm.card = card.name;
        vm.totalItems = clients.count;

        vm.parseArrayToHeaders(card.data);
        vm.items = clients.items;
        vm.parseHeadersToFields();
      });
    } catch (e) {
      await store.dispatch("alert/showError", e);
    }
  },

  computed: {
    currentItems() {
      return (this.$refs["table"] as any).internalCurrentItems.map(
        (item: any) => ({
          ...item,
          delete: false
        })
      );
    }
  },

  methods: {
    force() {
      this.forceKey++;
    },
    async loadData(): Promise<void> {
      this.loading = true;
      try {
        // const client = await API.clients().getClient(
        //   Number(this.$route.params.id)
        // );
        // const tableConfig = {
        //   date_from: "2021-07-01T00:00:00.000Z",
        //   date_to: "2021-07-30T23:59:59.000Z"
        // };
        const card = await API.cards().getClientCardById(
          Number(this.$route.params.id)
        );

        const clients = await API.clients().getClients(
          { card_id: Number(this.$route.params.id) },
          {}
        );

        this.name = card.name;
        this.totalItems = clients.count;
        this.items = clients.items;
        this.parseArrayToHeaders(card.data);
        this.parseHeadersToFields();
      } catch (e) {
        await this.$store.dispatch("alert/showError", "Неизвестная ошибка");
      }
      this.loading = false;
    },
    parseArrayToHeaders(arr: Array<any>): void {
      const tempArr: Array<any> = [
        {
          text: "delete",
          value: "delete",
          width: "30px",
          sortable: false
        },
        {
          text: "Дата создания",
          value: "created_at"
        }
      ];

      for (const item of arr) {
        tempArr.push({
          text: item.title,
          value: `field${item.id}`
        });
      }

      this.headers = tempArr;
    },
    parseHeadersToFields() {
      const parsedArr: Array<any> = [];
      const tempHeaderArr = this.headers;
      const tempItemsArr = this.items;

      tempItemsArr.forEach((item: any, index: any) => {
        parsedArr.push(this.getObject());

        for (const child of tempHeaderArr) {
          parsedArr[index][child.value] = tempItemsArr[index][child.value];
          parsedArr[index].id = tempItemsArr[index].id;
        }
      });
      this.parsedItems = parsedArr;
    },
    getObject(): any {
      const arr = this.headers;
      const tempObj: any = {};

      for (const item of arr) {
        tempObj[`${item.value}`] = "";
      }
      tempObj.created_at = "";
      return tempObj;
    },
    async clearBase(): Promise<any> {
      try {
        await this.$API.clients().clearBase(Number(this.$route.params.id));
        await this.loadData();
        await this.$store.dispatch(
          "alert/showSuccess",
          "База успешно очищена!"
        );
      } catch (e) {
        await this.$store.dispatch("alert/showError", "Неизвестная ошибка");
      }
    },
    async deleteRows(): Promise<void> {
      this.loading = true;
      try {
        await this.$API.clients().deleteRows({
          card: Number(this.$route.params.id),
          rows: this.getRowsToDelete()
        });
        await this.$store.dispatch(
          "alert/showSuccess",
          "Строки успешно удалены!"
        );
        await this.loadData();
        this.resetCheckboxes();
      } catch (e) {
        await this.$store.dispatch("alert/showError", "Неизвестная ошибка");
      }

      this.loading = false;
    },
    toggleCurrentPage(): void {
      this.deleteCurrentPage = !this.deleteCurrentPage;

      for (const item of this.currentItems) {
        item.delete = this.deleteCurrentPage;
      }
      this.force();
    },
    someHeadersVisible(): boolean {
      if (!this.currentItems) return false;

      let state = false;

      if (this.currentItems.some((item: any) => item.delete)) {
        state = true;
      }

      if (this.currentItems.every((item: any) => item.delete)) {
        state = false;
      }

      if (this.currentItems.every((item: any) => !item.delete)) {
        state = false;
      }

      return state;
    },
    setCheckboxState() {
      this.force();
      if (this.currentItems.every((item: any) => item.delete)) {
        this.deleteCurrentPage = true;
      }
      if (this.currentItems.every((item: any) => !item.delete)) {
        this.deleteCurrentPage = false;
      }

      this.force();
    },
    resetCheckboxes() {
      this.deleteCurrentPage = false;

      for (const item of this.currentItems) {
        item.delete = false;
      }
    },
    getRowsToDelete() {
      const arr: Array<number> = [];

      for (const item of this.currentItems) {
        if (item.delete) {
          arr.push(item.id);
        }
      }
      return arr;
    },
    async downloadFile(file: any): Promise<any> {
      if (file) {
        const element = document.createElement("a");

        if (file.url) {
          element.setAttribute("href", file.url);
        } else {
          const fileLink = (await this.toBase64(file)) as string;
          element.setAttribute("href", fileLink);
          element.setAttribute("download", file.name);
        }

        element.setAttribute("target", "_blank");
        element.click();
      }
    },
    async exportTable(value: string): Promise<any> {
      try {
        const response = value
          ? await this.$API.clients().exportHeaders(
              {
                card_id: Number(this.$route.params.id)
              },
              { responseType: "blob" }
            )
          : await this.$API.clients().exportFile(
              {
                card_id: Number(this.$route.params.id),
                date_from: "2021-07-01T00:00:00.000Z",
                date_to: "2021-07-30T23:59:59.000Z"
              },
              { responseType: "blob" }
            );

        const blob = new Blob([response], { type: "text/csv;charset=utf-8" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank";
        link.download = `${this.name}.csv`;
        link.click();
        URL.revokeObjectURL(link.href);
      } catch (e) {
        await this.$store.dispatch("alert/showError", "Неизвестная ошибка");
      }
    },
    async importTable(): Promise<any> {
      try {
        if (this.file) {
          const form = new FormData();
          form.append("card_id", `${Number(this.$route.params.id)}`);
          form.append("file", this.file);

          await this.$API.clients().importFile(form);
          await this.loadData();
          this.modal = false;
        }
      } catch (e) {
        await this.$store.dispatch("alert/showError", "Неизвестная ошибка");
      }
    }
  }
});
